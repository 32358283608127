import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import Section from "../components/section"
import { indexByFilename } from "../utils"

const WorkshopPage = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="電子工作教室"
        pathname="/workshop/"
        keywords={[
          `電子工作`,
          `近未来体験2020`,
          `近未来体験`,
          `五月祭`,
          `EEIC`,
          `電子情報工学科`,
          `電気電子工学科`,
          `東京大学`,
          `工学部`,
          `電気系`,
          `eeic_studentadv`,
        ]}
        description="電子工作を体験することができます。物作りという学校の勉強とは趣の異なる体験をお楽しみください。"
      />
      <Hero pageTitle="電子工作教室" pageSubtitle="Electrical Works" />
      <Section
        id="ework"
        headImage={data.eworkHeadImage}
      >
        <h1>電子工作教室</h1>
        <p>
          毎年好評をいただいております電子工作教室、今年もまた新たにオリジナル電子工作キットを用意しました。
          回路図、配線図共に学生の手作りです。
        </p>
        <p>
          今年度は3つのコースを用意しましたが、残念なことに実際に来場して作っていただくということができなくなってしまったため、制作過程を公開いたします。
        </p>
        <p>
          ぜひまた来年お越しいただければ嬉しいです。
        </p>
        <hr/>
        <div className="works-sections">
          <h2>マイコンLチカキット</h2>
          <p></p>
          <iframe width="670" height="377" src="https://www.youtube.com/embed/GC1EidSjl34" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <h4>動作解説</h4>
          <p>
            このLチカキットという名前は、LEDチカチカキットを略したものです。その名の通り家
            の明かりなどに使われるLEDをチカチカ点滅させて遊ぶもので、シンプルすぎてつまらな
            いと思うかもしれません。しかしLチカというのは奥が深いもので、数個のLEDの点滅を
            組み合わせたり、センサーと組み合わせたりすると非常に楽しく遊べます。
          </p>
          <br className="middle-on" />
          <p>
            Lチカキットの頭脳の役割、つまりLEDを付けたり消したりする役割を担うのがこの「マ
            イコン」と言われる部品です。「マイコン」は「マイクロコンピュータ」の略で、小さな
            パソコンだと思うとわかりやすいと思います。パソコンのように複雑なことが小さい部品
            でできるので大変便利です。
          </p>
          <br className="middle-on" />
          <p>
            LEDをマイコンでチカチカすると言っても、実際はどうやってLEDを点けたり消したり
            するのでしょうか？その制御の役割をするのが「プログラム」です。プログラムはマイコ
            ンの中に入っていて、マイコンに色々な命令を出します。つまり人間に例えると、プログ
            ラムは脳、マイコンは手足といった感じです。プログラムに“電流を流す”という意味の
            “HIGH”を書けば、LEDは光ります。逆に“電流を止める”という意味の“LOW”と書けば
            、LEDは光らなくなります。
          </p>
          <br className="middle-on" />
          <p>
            このようなことをプログラムに書いていくことで、動画にあるように綺麗にLEDを光らせ
            ることができる訳です。町をブラブラ歩いていると様々なライトがキラキラ点いたり消え
            たりしていますよね？それらも、ほとんどが今紹介したようなやり方で動いています。
          </p>
          <br className="middle-on" />
          <p>
            ちなみにこのLチカキットのLEDの3つは一つの色(橙色)しか出せないLEDを使っていて、
            残り一つだけ「RGBLED」という物も使っています。聞いたことがある人も多いかもしれ
            ませんが、人間は実は、赤(RED)、緑(GREEN)、青(BRUE)の三色しか見分けられません(
            これらの頭文字を取ってRGBと言います)。自然界にあるものはこの三色の光の強さが様
            々な割合で混ざっています。例えば、緑色に見える草は緑の光がほとんどの割合を占めて
            います。じゃあ紫色は？と思う方もいるでしょう。実は紫色は赤と青が半分ずつくらいの
            割合で混ざっています。
            RGBLEDはその赤、緑、青の光を自在に変化させられるLEDです。今回のキットでは、
            光の混ぜ具合で様々に変わる色を楽しんでもらう予定でした。動画にはその様子も映って
            いるので、見てみてください。
          </p>
          <br className="middle-on" />
          <p>
            また、このキットにはタッチセンサーというものも付いています。タッチ(触る)センサー
            ですので、その名の通り触ったことがプログラムに伝わるようになっています。プログラ
            ムはその情報を受け取って、様々な変化を起こすことができる訳です。例えば、「触った
            らLEDの点滅するスピードを変える」みたいなこともできるようになります。
          <br className="middle-on" />
            以上に紹介したことを組み合わせると、ただLEDを点滅させるだけなのに様々なことがで
            きます。実際に作って動かした動画を是非試しに見てください！
          </p>
          <h4>工夫した点</h4>
          <p>
            Lチカは電子回路をやったことがある人なら誰もが通る道だと思います。ものすごく基本的ですがマイコンを使えばできることの幅は非常に広く、電子工作教室にするにはピッタリだと思いこれを採用しました。
            回路自体は非常に簡単で、できるだけ部品点数を減らすことを目標にしました。これは、今までの電子工作教室ははんだ付けの時間が多すぎると言われていたためです。
            部品点数を減らして、小中学生が楽しめるようにはんだ付けは最小限に抑えているので、やる気次第でいくらでも楽しめるようになっています。
            LEDの配置は、マイコンでプログラムを書いた時に綺麗に見えるように横一列にしました。単色LED3つにRGBLED1つです。これによって、流れるようなLEDの制御がよりわかりやすくなっています。
            また、タッチセンサーはマイコンを除けばたった3つの部品で構成されており、非常に単純であることが分かりやすくしました。
            タッチセンサーのように一見難しく見えるものでも簡単であることがわかってもらえるかと思います。 
          </p>
          <h4>製作者からの一言 1</h4>
          <p>
            マイコンの面白さはプログラムにより動作を変更できる点にあります。
            そこで今回はハンダ付けに加えてプログラミングもやってもらい、マイコンを十分に楽しんでもらおうと考えました。
            また家に持ち帰ったあとでも引き続き楽しめるように配慮しました。
            パソコンにArduinoのIDEをインストールすれば自宅でプログラミングの続きができます。
            回路もとにかくシンプルにしてありますからプログラムとマイコンの動作の対応関係も理解しやすいでしょう。
            マイコン部分は取り外しができるようになってます。腕が上がったら自分で入出力回路を作ってそちらにマイコンを取り付けてください。
            対象は中学一年生以上を想定しています。
            だけどサンプルプログラムを準備してあり親切なチューターもいるから、がんばれる小学生も大丈夫かも。
          </p>
          <h4>製作者からの一言 2</h4>
          <p>
            3年生になったばかりの新居です。Lチカキットを担当しました。東大は教養課程が2年生
            の前半まであり、2年の後半から専門課程が始まります。つまり、本格的に電気の勉強を
            するのは2年生の後半からなんです。実は五月祭の準備は春休みの3月くらいからなので、
            この頃はまだちゃんと電子回路の勉強をしていなかったんです笑。小さい頃からロボット
            や機械に興味があったので、キットを組んだりしていましたが、本格的に勉強したのはこ
            の前の春休みからでした(勉強ではなく趣味で)。半田ごてや、テスター(電圧や電流を測る
            機械)を買ったのもこの前の春休みです。
            そんな状態から、このキットの開発と同時並行で勉強してどうにか完成まで辿り着きま
            した。実際に自分が書いたプログラム通りに動く、自分の想像通りの動作をしてくれる、
            というのは達成感や楽しさがあります。このページを見てくださった方々にもその楽しさ
            が少しでも伝わればいいなと思っています。新型コロナウイルスで大幅に五月祭関係の活
            動が制限されてしまい、EEIC(東京大学電気電子・電子情報工学科)ではオンラインでの五
            月祭となりました。本来子供達に直接電子工作の楽しさを教えられると思っていたので、
            その点少し残念ですが、来年新型コロナウイルスが収束した際には来ていただけだけると
            嬉しいです。
            4年生の先輩方には、Lチカキットの大部分を任せて頂いてだいぶ勉強になりました。ま
            た、色々なことを教えて頂きました。ありがとうございます。
          </p>
          <p></p>

          
        </div>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query {
    eworkHeadImage: file(relativePath: { eq: "ework.png" }) {
      ...SectionHeadImage
    }
  }
`

export default WorkshopPage
