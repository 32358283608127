import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import { ReactComponent as BulbBlack } from "../images/bulb-black.svg"

const Header = ({ siteTitle }) => (
  <header>
    <div>
      <div className="header-logo-container">
        <Link to="/">
          <div className="header-logo">
            <BulbBlack className="header-icon" height="45" width="36" />
            <h1>{siteTitle}</h1>
          </div>
        </Link>
      </div>
      <nav>
        <Link to="/programming/">プログラミング教室</Link>
        <Link to="/workshop/">電子工作教室</Link>
        <Link to="/exhibition/">学生制作物展示</Link>
        <a href="http://nanotechnet.t.u-tokyo.ac.jp/kairo/index.html">研究室紹介</a>
      </nav>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
