import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import Section from "../components/section"

const ExhibitionPage = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="展示"
        pathname="/exhibition/"
        keywords={[
          `電子情報機器学`,
          `研究室`,
          `近未来体験2020`,
          `近未来体験`,
          `五月祭`,
          `EEIC`,
          `電子情報工学科`,
          `電気電子工学科`,
          `東京大学`,
          `工学部`,
          `電気系`,
          `eeic_studentadv`,
        ]}
        description="学生制作物展示では学生が授業で制作したメカを展示します。研究室展示では最先端の研究や技術についてご紹介します。"
      />
      <Hero pageTitle="学生制作物展示" pageSubtitle="Exhibition" />
      <Section
        id="bdm"
        headImage={data.bdmHeadImage}
      >
        <h1>学生制作物展示</h1>
        <p>
          電子情報工学科・電気電子工学科には『電子情報機器学』という毎年恒例の名物授業があり、「何か一つ、面白いメカを作りなさい」という課題を渡され、学生たちは寝る間も惜しんでメカの制作に取り組みます。
        </p>
        <p>
          ここでは、最終授業で行われた発表会で特に優秀と認められた作品や発想が面白いと評価された作品を展示いたします。
        </p>
        <p>学生の柔軟な発想と高度な技術力に触れてみてはいかがでしょうか？</p>
        <hr />
        <div className="works-sections">
          <h3>LeLebo（自動ウクレレ演奏ロボット）</h3>
          <iframe width="670" height="377" src="https://www.youtube.com/embed/96nC2P-p3to" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <h4>製作者からの一言</h4>
          <p>
          LeLeboはウクレレを弾いてくれるロボットです。
          計24個のサーボモーターを駆使して、1つ1つの弦、フレットを個別に動作させることでリズミカルな演奏を可能にします。
          大事なウクレレを傷つけることなく装着・取り外しができます。Amazon Alexa などのAIアシスタントなどと連携して、「ウクレレ弾いて」と言って弾いてもらうこともできます。
          </p>
          <hr/>
          <h3>AAF(attached automatic fauset)</h3>
          <iframe width="670" height="377" src="https://www.youtube.com/embed/Ca-iNjk22qQ" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <h4>製作者からの一言</h4>
          <p>
            一般的な三角ハンドルの蛇口に手をかざすだけで水が出るような、取り付け型の装置を作りました。
            仕組みはシンプルで、人体赤外線感知素子が手を感知すると、モーターが回転し蛇口を捻ります。手が離れるとモーターは逆回転し、栓を閉めます。
            この装置は、介護の現場及び、衛生面が気になる昨今の状況などで大いに活躍します！！ 
          </p>
          <hr/>
          <h3>Eye-Fi</h3>
          <iframe width="670" height="377" src="https://www.youtube.com/embed/SDDfb6QRsfU" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <h4>製作者からの一言</h4>
          <p>
            電波という見えないものを見えるようにすれば面白いのでは？という思いつきから生まれました。
            回路や部品の設計からプログラミングまでいろんなことを考える必要があり、とてもためになりました。
            ぜひこれを通じて「可視化」を体験してみてください！！
          </p>
          <hr/>
          <h3>5x5x5 Full Color LED CUBE</h3>
          <iframe width="670" height="377" src="https://www.youtube.com/embed/_yF35d5t1BI" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <h4>製作者からの一言</h4>
          <p>
            「色の大胆かつ繊細な遷移を眼前で表現したい。」そんな目標を掲げて私たちが作成したのは，125個ものフルカラーLEDを等間隔に配置した視覚的出力デバイスです。複雑な配線が目立たないシンプルなデザインを意識して完成させました。
            この「5×5×5フルカラーLED CUBE」には，用意したアニメーションを次々と映し出すモードと，話し声や雑音などの環境音を入力として逐次的に表現するモードの二種類があります。どちらも「数」と「色」の表現力の広さを意識して、時に美しく、時にダイナミックに表現できるようにしました。
            <br className="mobile-off" />
            【動画の補足説明】
            <br className="mobile-off" />
            ・Auto Animation：
            LEDキューブ内を色が目まぐるしく変化するアニメーションです。色の鮮やかさとダイナミックな動きに注目していただければと思います。
            <br className="mobile-off" />
            ・Accumulation of Color：
            「声の熱量を表現できると面白いのではないか」という発想から作成しました。声量を音センサから取得し，グラデーションで色を変化させることができます。映像ではX JAPANさんの「紅」を熱唱することでLEDキューブを紅色に染めています。他にも、ろうそくの火を消すような優しい表現や，生活雑音を受け取ってしっとりと色を遷移させることも可能です。
            <br className="mobile-off" />
            ・Changing Color by Finger Pathion：
            作成者の私たちがマジックサークルに所属しているということもあり，「指パッチンで不思議なことを起こしたい」というモチベーションから作成しました。誰でも指を鳴らせば，マジシャンのように色を変えることができます。（指がならない人は手拍子でも大丈夫です！）
          </p>
          <hr/>
          <h3>ビックリ箱</h3>
          <iframe width="670" height="377" src="https://www.youtube.com/embed/hcj2tBee35Q" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <h4>製作者からの一言</h4>
          <p>
            びっくりする箱です。箱が私達をびっくりさせるのではありません。私達が箱を"わっ"と驚かせると、びっくりして一目散に逃げていったり、おたおたしてくるくる回ったりします。
            驚かせる方向によって逃げて行く向きが変わります。可愛らしいフォルムで究極の癒しロボットを目指しました。
          </p>
          <hr/>
          <h3>「フラッシュザウルス」っぽいゲーム</h3>
          <iframe width="670" height="377" src="https://www.youtube.com/embed/CUH3muuVsmI" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <h4>製作者からの一言</h4>
          <p>
            今は昔のTV番組、東京フレンドパークの人気アトラクション「フラッシュザウルス」をモチーフとしたゲームです。
            まず、本家の動画を1度観てください。これを作品の説明にかえさせて頂きます。
          </p>
        </div>
      </Section>
    </Layout>
  )
}


export const query = graphql`
  query {
    bdmHeadImage: file(relativePath: { eq: "bdm/じゃんけんマシン.jpg" }) {
      ...SectionHeadImage
    }
  }
`

export default ExhibitionPage
