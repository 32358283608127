import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import Section from "../components/section"
import { indexByFilename } from "../utils"

const WorkshopPage = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="電子工作教室"
        pathname="/workshop/"
        keywords={[
          `電子工作`,
          `近未来体験2020`,
          `近未来体験`,
          `五月祭`,
          `EEIC`,
          `電子情報工学科`,
          `電気電子工学科`,
          `東京大学`,
          `工学部`,
          `電気系`,
          `eeic_studentadv`,
        ]}
        description="電子工作を体験することができます。物作りという学校の勉強とは趣の異なる体験をお楽しみください。"
      />
      <Hero pageTitle="電子工作教室" pageSubtitle="Electrical Works" />
      <Section
        id="ework"
        headImage={data.eworkHeadImage}
      >
        <h1>電子工作教室</h1>
        <p>
          毎年好評をいただいております電子工作教室、今年もまた新たにオリジナル電子工作キットを用意しました。
          回路図、配線図共に学生の手作りです。
        </p>
        <p>
          今年度は3つのコースを用意しましたが、残念なことに実際に来場して作っていただくということができなくなってしまったため、制作過程を公開いたします。
        </p>
        <p>
          ぜひまた来年お越しいただければ嬉しいです。
        </p>
        <hr/>
        <div className="works-sections">
          <h2>可視光通信コース</h2>
          <p></p>
          <iframe width="853" height="480" src="https://www.youtube.com/embed/IW5M3Z1OXrc" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <p></p>
          <h4>動作解説</h4>
          <p>
            LEDの光を受信して、音に変換するキットです。Lチカキットと組み合わせて使うこと
            ができます。
            <br className="middle-on" />
            基板の端に配置されているLEDによく似た部品は「フォトトランジスタ」といいます。
            この素子は受け取った光の大きさに応じて流れる電流を変化させます。さらに抵抗と組み
            合わせることで、光の強弱を電気信号へと変換しています。
            回路の後半の部分は、電気信号を音に変換する回路です。トランジスタを使ってスピーカ
            ーを動かしています。
            <br className="middle-on" />
            実際に使うときは、Lチカキットのプログラムを可視光通信用に変更したうえで、LED
            と受信側のフォトトランジスタを向かい合わせます。電源を入れるとスピーカーから音が
            聞こえてくるはず……！距離を変えてみたり手で光を遮ったり、色々実験してみてくださ
            い。
            <br className="middle-on" />
            
            ところで、身近にあふれるWi-Fiやスマートフォンの通信は、電気信号を電波に変えてデ
            ータをやり取りしています。今回の可視光通信は、その電波が可視光（目に見える光）に
            変わっただけなのです！
            では、電波と可視光で一体何が違うのでしょうか……。実は、この2つはどちらも同じ電
            磁波の仲間なのですが、性質は大きく異なります。例えば、可視光は手で遮れますが電波
            は……？シンプルながら、かなり発展的な内容まで扱えるキットとなっています。
          </p>
          <h4>工夫した点</h4>
          <p>
            設計にあたり、できるだけ少ない部品でシンプルな回路にすることに気を付けました。
            前半の受光部分は電気系の先生に教わった回路です。たった2素子で信号の復調までこな
            すスマートさが特徴です。ここでの抵抗の値が電気信号の電圧振幅を決めるため、受信す
            る光の色（＝波長）や強度に合わせてちょうどいい値をとる必要があり、試行錯誤に苦労
            しました。
            <br className="middle-on" />
            後半のトランジスタを含んだ回路は「コレクタ接地回路」と言います。電圧増幅率が1
            である代わりに、入力インピーダンスを高く・出力インピーダンスを低く設定できるため
            、少ない部品で小型スピーカーを駆動するのにピッタリです。こちらは計算によって抵抗
            やコンデンサの値を定めました。
          </p>
          <h4>製作者からのコメント 1</h4>
          <p>
          音楽を転送してる様子を可視化できて面白いと思います。
          </p>
          <h4>製作者からのコメント 2</h4>
          <p>今やあって当然なものとなっている通信を、電子工作を通してより身近なものに感じていただけると嬉しいです。</p>
        </div>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query {
    eworkHeadImage: file(relativePath: { eq: "ework.png" }) {
      ...SectionHeadImage
    }
  }
`

export default WorkshopPage
