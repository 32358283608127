import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import Section from "../components/section"
import { indexByFilename } from "../utils"

const WorkshopPage = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="電子工作教室"
        pathname="/workshop/"
        keywords={[
          `電子工作`,
          `近未来体験2020`,
          `近未来体験`,
          `五月祭`,
          `EEIC`,
          `電子情報工学科`,
          `電気電子工学科`,
          `東京大学`,
          `工学部`,
          `電気系`,
          `eeic_studentadv`,
        ]}
        description="電子工作を体験することができます。物作りという学校の勉強とは趣の異なる体験をお楽しみください。"
      />
      <Hero pageTitle="電子工作教室" pageSubtitle="Electrical Works" />
      <Section
        id="ework"
        headImage={data.eworkHeadImage}
      >
        <h1>電子工作教室</h1>
        <p>
          毎年好評をいただいております電子工作教室、今年もまた新たにオリジナル電子工作キットを用意しました。
          回路図、配線図共に学生の手作りです。
        </p>
        <p>
          今年度は3つのコースを用意しましたが、残念なことに実際に来場して作っていただくということができなくなってしまったため、制作過程を公開いたします。
        </p>
        <p>
          ぜひまた来年お越しいただければ嬉しいです。
        </p>
        <hr/>

        <div className="hori-sections">
          <Link to="/micon/" className="block-link">
            <Section headImage={data.miconImage}>
              <h4>マイコンLチカキット</h4>
            </Section>
          </Link>
          <Link to="/ledcom/" className="block-link">
            <Section headImage={data.ledcomImage}>
              <h4>可視光通信コース</h4>
            </Section>
          </Link>
          <Link to="/solar/" className="block-link">
            <Section headImage={data.solarImage}>
              <h4>太陽光モーターコース</h4>
            </Section>
          </Link>
        </div>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query {
    eworkHeadImage: file(relativePath: { eq: "ework.png" }) {
      ...SectionHeadImage
    }
    miconImage: file(relativePath: { eq: "micon.jpg" }) {
      ...SectionHeadImage
    }
    ledcomImage: file(relativePath: { eq: "ledcom.jpg" }) {
      ...SectionHeadImage
    }
    solarImage: file(relativePath: { eq: "solar.jpg" }) {
      ...SectionHeadImage
    }
  }
`

export default WorkshopPage
