import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import Section from "../components/section"
// import { indexByFilename } from "../utils"
import { ReactComponent as BulbWhite } from "../images/bulb-white.svg"
import { ReactComponent as TSUKUMO } from "../images/TSUKUMO.svg"

const IndexPage = ({ data }) => {
  // const linkImageMap = indexByFilename(data.linkImage)
  return (
    <Layout>
      <SEO
        keywords={[
          `近未来体験2020`,
          `近未来体験`,
          `五月祭`,
          `EEIC`,
          `電子情報工学科`,
          `電気電子工学科`,
          `東京大学`,
          `工学部`,
          `電気系`,
          `電子情報機器学`,
          `電子工作`,
          `プログラミング`,
          `VTuber`,
          `バーチャルYouTuber`,
          `御城みつき`,
          `風里えれな`,
          `VR`,
          `AR`,
          `mitsuele_eeic`,
          `eeic_studentadv`,
        ]}
      />
      <Hero className="index-hero">
        <div className="index-logo">
          <BulbWhite height="90" />
        </div>
        <p>
          東京大学工学部
          <br className="mobile-on" />
          {` `}
          電子情報工学科・電気電子工学科
          <br className="mobile-on" />
          {` `}
        </p>
        <h2>
          近未来体験
          <br className="narrow-on" />
          2020
        </h2>
      </Hero>
      <Section id="about">
        <h2>About</h2>
        <p>
          「近未来体験2020」は電子情報工学科・電気電子工学科の学生が主催する
          <br className="middle-on" />
          五月祭展示企画のことです。
          <br className="mobile-off" />
          幅広い分野の最先端の研究や、学生がオリジナルに制作したメカやゲームを
          <br className="middle-on" />
          見て聞いて体験していただけます。
        </p>
        <p>
          しかし残念ながら今年はCOVID-19の影響で五月祭が本来の日程で開催できなくなってしまいました。
          <br className="mobile-off" />
          そこで今年はwebサイト上で我々電気系の学生が五月祭のために制作してきたものを公開しようと思います。
        </p>
        <p>
          電気・電子・情報に興味のある大学生や高校生はもちろん、
          <br className="middle-on" />
          学校が休みになり暇になってしまった子供や、
          <br className="mobile-off" />
          進学選択に迷っている東大生にも、
          <br className="middle-on" />
          幅広い方に電気系の魅力を伝えられたらと思います。
        </p>
        <p>ぜひ楽しんでいってください！</p>
        
      </Section>
      <Section id="contents">
        <h2>企画</h2>
        <div className="hori-sections">
          <Link to="/programming/" className="block-link">
            <Section headImage={data.pikyoImage}>
              <h3>プログラミング教室</h3>
              <p>
                プログラミング教室では学生が自作したゲームを通じて簡単にプログラミングの基礎を学ぶことができます。プログラミングについて何も知らなくても、プログラミングをはなんぞやを分かりやすく体験できるつくりになっています。
              </p>
            </Section>
          </Link>
          <Link to="/workshop/" className="block-link">
            <Section headImage={data.workshopImage}>
              <h3>電子工作教室</h3>
              <p>
                電子工作教室では学生が自作したキットを通じて電子工作を体験することができます。理系の中でも工学部、とりわけEEICでは「モノを創る」ことに重きを置いています。ものづくりという学校の勉強とは趣の異なる体験を子どもも大人の方もお楽しみください。
              </p>
            </Section>
          </Link>
          <Link to="/exhibition/" className="block-link">
            <Section headImage={data.exhibitionImage}>
              <h3>学生制作物展示</h3>
              <p>
                EEICでは電子情報学機器学というこれまでの授業で得た知識を生かして実際に手を動かして自分の好きなものを作る授業があります。ここでは授業で製作された様々な作品のなかでも優秀なものを展示しています。
              </p>
            </Section>
          </Link>
          <a href="http://nanotechnet.t.u-tokyo.ac.jp/kairo/index.html" className="block-link">
            <Section headImage={data.labImage}>
              <h3>研究室紹介</h3>
              <p>
                EEICはエネルギー、半導体、コンピュータシステム、人工知能などハードからソフトに渡るまで幅広い分野を得意としています。ここではそんなEEICに関わる様々な研究室を紹介しています。（外部サイト）
              </p>
            </Section>
          </a>
        </div>
      </Section>
      
      <Section id="sponsor">
        <h2>協賛</h2>
        <a
          className="block-link"
          style={{ padding: `0 1rem 1px` }}
        >
          <div>
            <TSUKUMO className="ext-logo" width="500" />
          </div>
          <p>
            パソコン・パソコンパーツの専門店ツクモです。
            <br className="mobile-off" />
            ツクモオリジナルPCの開発・販売のほか、各種VR機器を取り扱っております。
          </p>
        </a>
      </Section>
      
      
    </Layout>
  )
}

export const query = graphql`
  query {
    pikyoImage: file(relativePath: { eq: "pikyo.jpg" }) {
      ...SectionHeadImage
    }
    workshopImage: file(relativePath: { eq: "workshop.jpg" }) {
      ...SectionHeadImage
    }
    exhibitionImage: file(relativePath: { eq: "exhibition.png" }) {
      ...SectionHeadImage
    }
    labImage: file(relativePath: { eq: "lab.jpg" }) {
      ...SectionHeadImage
    }
    # linkImage: allFile(filter: { relativeDirectory: { eq: "link" } }) {
    #   edges {
    #     node {
    #       name
    #       childImageSharp {
    #         fixed(width: 300) {
    #           ...GatsbyImageSharpFixed
    #         }
    #       }
    #     }
    #   }
    # }
  }
`

export default IndexPage
