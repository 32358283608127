import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import Section from "../components/section"
import { indexByFilename } from "../utils"
import { Carousel } from "react-bootstrap"
import Img from "gatsby-image"

const ProgrammingPage = ({ data }) => {
  const photoMap = indexByFilename(data.photos)
  return (
    <Layout>
      <SEO
        title="展示"
        pathname="/programming/"
        keywords={[
          `プログラミング`,
          `近未来体験2020`,
          `近未来体験`,
          `五月祭`,
          `EEIC`,
          `電子情報工学科`,
          `電気電子工学科`,
          `東京大学`,
          `工学部`,
          `電気系`,
          `eeic_studentadv`,
        ]}
        description="プログラミング教室では電子情報工学科・電気電子工学科の学生がプログラミングを学べるゲームを作成・展示しています。"
      />
      <Hero pageTitle="プログラミング教室" pageSubtitle="Programming" />
      <Section
        id="programming"
        headImage={data.pgmHeadImage}
      >
        <h1>プログラミング教室</h1>

        <p>
          ここでは、電子情報工学科・電気電子工学科の学生が作成したプログラミングを学べるゲームを展示いたします。
        </p>
        <div className="works-sections">
          <h3><a href="/programming/game/index.html">ゲームで遊ぶ</a></h3>
        </div>

        <div className="works-sections">
          <h3>ルール</h3>
          <p>キャラクターを操作してゴールまで動かそう！</p>
          <Carousel>
            <Carousel.Item>
              <Img className="d-block"
                fluid={photoMap.tutorial01.childImageSharp.fluid} />
            </Carousel.Item>
            <Carousel.Item>
              <Img className="d-block"
                fluid={photoMap.tutorial02.childImageSharp.fluid} />
            </Carousel.Item>
            <Carousel.Item>
              <Img className="d-block"
                fluid={photoMap.tutorial03.childImageSharp.fluid} />
            </Carousel.Item>
          </Carousel>
        </div>

        <div className="works-sections">
          <p>昨年の五月祭で展示したゲームも<a href="https://dev.tose2125.net/eeic19pro/">こちら</a>にあります。遊んでみてね！</p>
        </div>
      </Section>
    </Layout>
  )
}


export const query = graphql`
  query {
    pgmHeadImage: file(relativePath: { eq: "pikyo.jpg" }) {
      ...SectionHeadImage
    }
    photos: allFile(filter: { relativeDirectory: { eq: "programming" } }) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default ProgrammingPage
