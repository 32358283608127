import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import Section from "../components/section"
import { indexByFilename } from "../utils"

const WorkshopPage = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="電子工作教室"
        pathname="/workshop/"
        keywords={[
          `電子工作`,
          `近未来体験2020`,
          `近未来体験`,
          `五月祭`,
          `EEIC`,
          `電子情報工学科`,
          `電気電子工学科`,
          `東京大学`,
          `工学部`,
          `電気系`,
          `eeic_studentadv`,
        ]}
        description="電子工作を体験することができます。物作りという学校の勉強とは趣の異なる体験をお楽しみください。"
      />
      <Hero pageTitle="電子工作教室" pageSubtitle="Electrical Works" />
      <Section
        id="ework"
        headImage={data.eworkHeadImage}
      >
        <h1>電子工作教室</h1>
        <p>
          毎年好評をいただいております電子工作教室、今年もまた新たにオリジナル電子工作キットを用意しました。
          回路図、配線図共に学生の手作りです。
        </p>
        <p>
          今年度は3つのコースを用意しましたが、残念なことに実際に来場して作っていただくということができなくなってしまったため、制作過程を公開いたします。
        </p>
        <p>
          ぜひまた来年お越しいただければ嬉しいです。
        </p>
        <hr/>
        <div className="works-sections">
          <h2>太陽光モーターコース</h2>
          <iframe width="670" height="377" src="https://www.youtube.com/embed/Q5fsVFesRxI" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <h4>動作解説</h4>
          <p>
            この回路の一番のキーポイントは真ん中にある青い部品です。これはなんでしょう？これはキャパシタ(コンデンサ)と呼ばれるもので、
            電気をためることができます。充電ができる電池も電気をためることができますが、キャパシタは少し違った特徴を持っていて、
            もっと短い時間に電気をためておくのに適しています。
          </p>
          <p>
            さて、この回路には真ん中のキャパシタに電気をためるモードと、溜まった電気でモーターを回すモードの2つのモートがあり、
            右上のレバーでモードを切り替えます。どれくらいキャパシタに電気が溜まったかは、右下のメーターで確かめることができます。
          </p>
          <p>
            でも一つ問題があって、このメーターを動かすためには左下に新しく電池を付けないといけなかったんです、、！
            キャパシタに溜まっている電圧がどれくらいなのかを知るためには、それよりも高い安定した電圧が必要になるんですね。
            全てを太陽電池の力だけで動かすことは、一つの大きな課題です。
          </p>
          <h4>工夫した点</h4>
          <p>
            太陽電池が出せる電流は普通の電池より小さいです。そこで、小さい電流でもよく回ってくれる、ソーラーモーターという物を使いました。
            また、メーターの最大値の電圧をいくつにするかを繋げる抵抗によって選ぶことができます。今回は5Vで最大になるように調整しました。
            ただし、動画ではメーターを動かすバッテリーを3Vしかつなげていないので、キャパシタの電圧が2Vを超えたあたりから表示がちらついてしまっています。
            このメーターは正しくは5V以上の電源を使わないといけないので、真似しないように注意してください。
          </p>
          <h4>製作者からの一言</h4>
          <p>太陽電池にモーターを繋いだら回る。みなさん当たり前と思っていませんか？実は普通のモーターでは太陽電池に繋いでもなかなか回ってくれません。
            太陽電池の出せるエネルギーは、太陽光がどれくらい当たっているかに大きく影響を受けます。私も小さい頃太陽電池を使ったキットを使ってみて、
            動作がとても不安定だったことに不満を隠せませんでした。でも雨の日だって、モーターを回したい。出力が小さくても一旦溜めればいいんじゃないの？
            そんな思いから生まれたコースです。
          </p>
        </div>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query {
    eworkHeadImage: file(relativePath: { eq: "ework.png" }) {
      ...SectionHeadImage
    }
  }
`

export default WorkshopPage
