// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-exhibition-js": () => import("./../src/pages/exhibition.js" /* webpackChunkName: "component---src-pages-exhibition-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ledcom-js": () => import("./../src/pages/ledcom.js" /* webpackChunkName: "component---src-pages-ledcom-js" */),
  "component---src-pages-micon-js": () => import("./../src/pages/micon.js" /* webpackChunkName: "component---src-pages-micon-js" */),
  "component---src-pages-programming-js": () => import("./../src/pages/programming.js" /* webpackChunkName: "component---src-pages-programming-js" */),
  "component---src-pages-solar-js": () => import("./../src/pages/solar.js" /* webpackChunkName: "component---src-pages-solar-js" */),
  "component---src-pages-vote-js": () => import("./../src/pages/vote.js" /* webpackChunkName: "component---src-pages-vote-js" */),
  "component---src-pages-workshop-js": () => import("./../src/pages/workshop.js" /* webpackChunkName: "component---src-pages-workshop-js" */)
}

